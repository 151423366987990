export const ACERTIJOS = 'acertijos'
export const EMOJIS = 'emojis'
export const LOGOS = 'logos'
export const PELICULAS = 'peliculas'
export const JUGADORES = 'jugadores'
export const SOMBRAS = 'sombras'
export const FUNKOS = 'funkos'
export const ESCUDOS = 'escudos'
export const BANDERAS = 'banderas'
export const ALEATORIO = 'aleatorio'
export const WUZZLES = 'wuzzles'
export const QUESTIONS = 'preguntas'
export const ENG = 'eng'
export const ESP = 'esp'
export const LAT = 'lat'
